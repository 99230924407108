.material-Navbar {
    background-color: #151A22; /* Barva pozadí navigace */
    max-width: 1280px; /* Maximální šířka */
    margin: 0 auto; /* Centrování navigace */
    padding: 0 20px; /* Přidání paddingu pro lepší vzhled */
    border-bottom-left-radius: 10px; /* Zaoblení levého spodního rohu */
    border-bottom-right-radius: 10px; /* Zaoblení pravého spodního rohu */
    height: auto; /* Výška navigace */
}
  
.material-MenuNavbar a {
    text-decoration: none; /* Odstranění podtržení u odkazů */
    color: #C5C6C7; /* Barva textu */
}

.material-MenuNavbar a:hover {
    color: #FF4200; /* Změna barvy textu při najetí myši */
}

.material-MenuNavbar {
    display: flex; /* Použití flexboxu pro uspořádání prvků vedle sebe */
    justify-content: space-around; /* Rovnoměrné rozložení prvků s mezerami kolem */
    padding: 20px; /* Odsazení od okrajů */
    align-items: center; /* Vertikální zarovnání prvků na střed */
    flex-wrap: wrap; /* Povolení zalamování prvků */
}

.material-MenuNavbar-item {
    color: #C5C6C7; /* Barva textu */
    padding: 5px 10px; /* Odsazení textu uvnitř odkazu */
    border: 2px solid #C5C6C7; /* Přidání transparentního rámečku */
    border-radius: 5px; /* Zaoblení rohů */
    cursor: pointer; /* Změna kurzoru na ukazatel */
    transition: background-color 0.3s, border-color 0.3s; /* Plynulá změna barvy */
    margin-right: 20px; /* Odsazení mezi jednotlivými prvky */
    margin-bottom: 10px; /* Odsazení mezi řádky na malých obrazovkách */
}

.material-MenuNavbar-item:last-child {  
    margin-right: 0; /* Odstranění odsazení u posledního prvku */   
}
  
.material-MenuNavbar-item:hover {
    color: #FF4200; /* Změna barvy textu při najetí myši */
}

@media (max-width: 768px) {
    .material-MenuNavbar {
      flex-direction: column; /* Prvky pod sebou */
      align-items: center; /* Centrování prvků */
    }
    .material-MenuNavbar-item {
      margin-right: 0; /* Odstranění marginu vpravo */
      margin-bottom: 10px; /* Přidání marginu dole pro mezery mezi prvky */
    }
    .material-MenuNavbar-item:last-child {
      margin-bottom: 0; /* Odstranění marginu dole u posledního prvku */
    }
  }