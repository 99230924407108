.hardness-comparison {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 600px;
    margin: 20px auto;
  }
  
  .input-field {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .select-field {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
  
  input {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    width: 200px;
  }

  select {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    width: 200px;
  }
  
  .hardness-input {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    width: 100px;
    text-align: center;
  }
  
  .result-field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 70%;
  }
  
  .hardness-item {
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    width: 100%;
  }
  
  .hardness-key {
    font-weight: bold;
  }
  
  .hardness-value {
    color: #333;
  }
  