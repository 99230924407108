.materials-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Odstup mezi kartami */
  justify-content: start;
  max-width: 1280px; /* Maximální šířka kontejneru */
  margin: 0 auto; /* Centrování kontejneru */
}

.material-item {
  border: 1px solid #C5C6C7; /* Rámeček */
  border-radius: 5px; /* Zaoblené rohy */
  padding: 20px; /* Vnitřní odsazení */
  margin: 10px; /* Vnější odsazení */
  width: calc(100% - 40px); /* Plná šířka pro malé obrazovky */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Lehký stín */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Animace pro hover */
}

.material-item:hover {
  cursor: pointer; /* Ukazatel při hover */
  transform: translateY(-5px); /* Posun karty nahoru při hover */
  box-shadow: 0 4px 8px #ff4200; /* Zvýraznění stínu při hover */
  border-color: #ff4200; /* Změna barvy rámečku při hover */
}

/* Media queries */
@media (min-width: 480px) {
  .material-item {
      width: calc(50% - 40px); /* Dvě karty v řadě pro střední obrazovky */
  }
}

@media (min-width: 768px) {
  .material-item {
      width: calc(33.333% - 40px); /* Tři karty v řadě pro velké obrazovky */
  }
}

@media (min-width: 1024px) {
  .material-item {
      width: calc(25% - 40px); /* Čtyři karty v řadě pro velmi velké obrazovky */
  }
}

.lists-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.search-container {
  justify-content: center; /* Centruje prvek na střed stránky vodorovně */
  display: flex; /* Zajistí, že prvek bude mít vlastní řádek a bude se chovat jako blokový prvek */
  padding: 20px; /* Přidává odsazení uvnitř prvku pro vizuální prostor */
}