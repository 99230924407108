/* return (
  <form>  
    <div className="group-all-other">     
      <div className="group-material-info">        
      </div>      
      <div className="group-other-names-coefficients">  
      </div>
    </div>
    <div className="group-material-description">
    </div>
    <div className="group-admin-check">  
    </div>
    <div className="group-submit">
    </div>
  </form>
);
*/


form {
  display: flex;
  flex-direction: column; /* Položky pod sebou */
  max-width: 1280px; /* Maximální šířka */
  width: 100%; /* Zajistí, že form bude responzivní */
  margin: 0 auto; /* Centrování */
  padding: 20px; /* Přidáváme padding pro lepší vzhled */
  box-sizing: border-box; /* Zahrnuje padding do šířky */
}

/* Responzivní styly pro menší obrazovky */
@media (max-width: 768px) {
  form {
    padding: 10px; /* Menší padding na menších obrazovkách */
  }

  .group-material-info, .group-other-names-coefficients, .group-material-description, .group-admin-check {
    padding: 5px; /* Menší padding pro všechny skupiny */
  }

  .group-submit button {
    width: 100%; /* Tlačítko na celou šířku pro lepší dostupnost */
  }
}

/* Další přizpůsobení pro velmi malé obrazovky */
@media (max-width: 480px) {
  .group-material-info, .group-other-names-coefficients, .group-material-description, .group-admin-check {
    flex-direction: column; /* Změna uspořádání na vertikální pro lepší čitelnost */
  }
}



.group-all-other {
  display: flex; /* Zobrazí obsah vedle sebe */
  width: 100%; /* Zabere celou dostupnou šířku */
}

.group-material-info {
  display: flex;
  flex-direction: column; /* Položky se zobrazí pod sebou */
  flex: 2; /* Zabere 2/3 dostupného prostoru */
  margin-right: auto; /* Zajistí, že bude vpravo rozumně od kraje */
  margin-bottom: 20px; /* Přidáváme mezery mezi jednotlivými skupinami */
}

.group-other-names-coefficients {
  flex: 1; /* Zabere zbylou 1/3 dostupného prostoru */
  display: flex;
  flex-direction: column; /* Položky se zobrazí pod sebou */
  margin-right: auto;
}

.group-material-info input, .group-other-names-coefficients input {
  width: 100%; /* Základní nastavení šířky */
  max-width: 400px; /* Maximální šířka inputů */
  margin-left: 0; /* Zarovnání inputů doleva */
  display: block; /* Zajistí, že každý input bude na novém řádku */
}

.margin-bottom {
  margin-bottom: 20px; /* Přidáváme mezery mezi jednotlivými inputy */
}

button {
  max-width: fit-content; /* Šířka podle obsahu */
  background-color: transparent; /* Průhledné pozadí */
  color: #C5C6C7;
  padding: 5px 10px;
  border: 2px solid #C5C6C7;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

button:hover {
  color: #FF4200;
  border-color: #FF4200;
}

.group-material-description {
  display: flex; /* Aktivuje flexbox */
  flex-direction: column; /* Prvky pod sebou */
  align-items: flex-start; /* Zarovnání doleva */
  padding: 10px; /* Rozumné odsazení */
  margin-bottom: 20px; /* Přidáváme mezery mezi jednotlivými skupinami */
}

.group-material-description textarea {
  width: 100%; /* Maximální šířka */
  max-width: 100%; /* Zabraňuje překročení rodičovského prvku */
  box-sizing: border-box; /* Zahrnuje padding a border do šířky */
}

.group-admin-check input[type="checkbox"] {
  /* Zvětšení checkboxu */
  transform: scale(1.5); /* Zvětší checkbox o 50% */
  cursor: pointer; /* Změní kurzor na ukazatel při najetí */
}

.group-admin-check label {
  cursor: pointer; /* Změní kurzor na ukazatel při najetí na text */
  margin-right: 20px; /* Odsazení od checkboxu */
}