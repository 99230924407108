
.firstNavbar {
  top: 0;
  width: 100%;
  background-color: #0B0C10;
  overflow: hidden;
  display: flex;
  justify-content: left;
  align-items: center; /* Vertikálně zarovná elementy na střed */
  padding: 14px 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  z-index: 1000;
}


.homeIcon {
  width: 40px; /* Přizpůsobte velikost podle potřeby */
  height: 40px; /* Přizpůsobte velikost podle potřeby */
  margin-right: 10px; /* Mezera mezi ikonou a textem */
}

.mainNavbar {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Vertikálně zarovná elementy na střed */
  background-color: #0B0C10;
  padding: 14px 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  z-index: 1000;
  max-width: 1280px; /* Maximální šířka */
  margin: 0 auto; /* Centrování při větších obrazovkách */
}

.leftSection {
  display: flex;
  align-items: center;
}

.mainMenuNavbar {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Horizontálně zarovná elementy na začátek */
  flex-grow: 1; /* Zajistí, že tato sekce zabírá zbylé místo mezi levým a pravým blokem */
}

.mainMenuNavbar a, 
.loginSection {
  display: inline-block;
  padding: 5px 10px; /* Odsazení textu uvnitř odkazu */
  margin: 5px; /* Odsazení mezi odkazy */
  border: 1px solid white; /* Tenký bílý rámeček */
  border-radius: 5px; /* Zaoblené rohy */
  transition: background-color 0.3s, border-color 0.3s; /* Plynulá animace pro změnu barvy */
  color: #C5C6C7; /* Bílá barva textu */
  text-decoration: none; /* Odstraní podtržení */
}

.mainMenuNavbar a:hover {
  color: #ff4200; /* Oranžová barva textu při najetí myši */
  border-color: #ff4200; /* Oranžový rámeček při najetí myši */
}

.loginSection {
  display: flex;
  align-items: center;
}

.login-indicator {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #ff4200; /* Oranžová pro nepřihlášeného uživatele */
  margin-right: 10px; /* Mezera mezi indikátorem a odkazem na přihlášení */
}

.loginSection a {
  color: #C5C6C7;
  text-decoration: none;
}

.loginSection a:hover, 
.loginSection:hover {
  color: #ff4200; /* Oranžová barva textu při najetí myši */
  border-color: #ff4200; /* Oranžový rámeček při najetí myši */
}

@media (max-width: 768px) {
  .mainNavbar {
    flex-direction: column; /* Prvky pod sebou */
    align-items: center; /* Centrování prvků */
  }
  .mainMenuNavbar {
    justify-content: center; /* Centrování prvků */
  }
  .mainMenuNavbar a {
    margin-right: 0; /* Odstranění marginu vpravo */
    margin-bottom: 10px; /* Přidání marginu dole pro mezery mezi prvky */
  }
}