.material-detail {
    max-width: 640px; 
    width: 100%; 
    margin: 0 auto;
    padding: 20px;
  }
  
  @media (max-width: 640px) {
    .material-detail, .material-properties-table {
      width: 100%; 
    }
  }

.material-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header-button {
  background-color: #1F2833;
  display: inline-block;
  padding: 5px 10px; 
  margin: 5px; 
  border: 1px solid white; 
  border-radius: 5px; 
  transition: background-color 0.3s, border-color 0.3s; 
  color: #C5C6C7; 
  text-decoration: none; 
}

.header-button:hover {
  color: #ff4200; 
  border-color: #ff4200; 
}

.material-properties-table {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.material-other-names {
  margin-bottom: 20px;
}

.material-properties {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
}

.delete-button {
display: flex;
justify-content: right;
}